export const postRedirect = (url, data) => {
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = url;
  form.target = '_blank';

  for (const el in data) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = el;
    input.value = data[el];
    form.appendChild(input);
  }

  document.body.appendChild(form);
  form.submit();
};
