import axios from '../../axios/authAxios';
import jwtDecode from 'jwt-decode';
import { isMobileDevice } from '@/utils/helpers';
import { useTypeLoginPassword } from '@/hooks/useTypeLoginPassword';

const TOKEN_KEY = 'jwt-token';

export default {
  namespaced: true,
  state () {
    return {
      token: localStorage.getItem(TOKEN_KEY)
    };
  },
  mutations: {
    setToken (state, token) {
      state.token = token;
      localStorage.setItem(TOKEN_KEY, token);
    },
    clear (state) {
      state.token = null;
      localStorage.removeItem(TOKEN_KEY);
    }
  },
  actions: {
    logout ({ _, dispatch }) {
      dispatch('reset', null, { root: true });
      const { removeTypeLoginPassword } = useTypeLoginPassword();
      removeTypeLoginPassword();
    },
    async checkLogin (_, payload) {
      try {
        return await axios.post('/auth/CheckAuth/PersonalAccount', payload);
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async login ({ commit }, payload) {
      try {
        const { data } = await axios.post('/auth/login', payload);
        commit('setToken', data.access_token);
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async createPasswordRecoverCode (_, payload) {
      try {
        const response = await axios.post('/auth/recover/code', {
          ...payload,
          isMobile: isMobileDevice() && payload.type === 'mobile'
        });
        return response.data;
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async recoverPassword (_, payload) {
      try {
        await axios.post('/auth/recover', payload);
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    }
  },
  getters: {
    token (state) {
      return state.token;
    },
    isAuthenticated (state) {
      return !!state.token;
    },
    jwtData (state) {
      return jwtDecode(state.token);
    }
  }
};
