<template>
  <AppBaner
    v-if="$route.path==='/'"
    class="banners"
  />
  <div class="page__container flex-fill">
    <AppBreadcrumbs />

    <div :class="['page__main', {'page__main_noaside': asideType === 'noaside'}, {'content__center': contentCenter}]">
      <slot name="menu" />
      <slot
        v-if="showBanners"
        name="banners"
      >
        Список баннеров для всех страниц
      </slot>
      <slot />
    </div>
    <div
      v-if="isDisableLK && qrCode"
      class="qr-code"
      :class="{'clickable': qrLink}"
      @click="goToQr"
    >
      <img
        class="qr-code-img"
        :src="qrCode"
        alt="qr-code"
      >
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import AppBaner from '@/components/baner/AppBaner';
import AppBreadcrumbs from '@/components/ui/AppBreadcrumbs';
import { useStore } from 'vuex';

export default {
  components: { AppBreadcrumbs, AppBaner },
  props: {
    showBanners: {
      type: Boolean,
      required: false
    },
    contentCenter: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const route = useRoute();

    const asideType = computed(() => route.meta.asideType);
    const store = useStore();
    const isDisableLK = store.getters['cms/getIsDisableLK'];
    const qrCode = store.getters['cms/getQrCode'];
    const qrLink = store.getters['cms/getQrLink'];

    const goToQr = () => {
      if (qrLink) window.open(qrLink, '_blank');
    };
    return { asideType, isDisableLK, qrCode, qrLink, goToQr };
  }

};
</script>
<style scoped>

.banners {
  margin-bottom: 32px;
}
.content__center {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.qr-code {
  align-self: center;
  max-width: 400px;
  max-height: 400px;
  margin-top: 20px;
}
.qr-code-img {
  max-width: 100%;
  max-height: 100%;
  min-height: 100%;
  object-fit: cover;
}
@media (max-width: 1279px) {
  .banners {
    margin: 0;
  }
}

</style>
