import axios from '../../axios/mainAxios';
import { isMobileDevice, parseDateDocument } from '@/utils/helpers';

export default {
  namespaced: true,
  state: {
    documents: [],
    contracts: []
  },
  mutations: {
    setInformingStatus (state, informingStatus) {
      state.informingStatus = informingStatus;
    },
    clear (state) {
      state.informingStatus = null;
      state.documents.length = 0;
      state.contracts.length = 0;
    },
    setDocuments (state, newDocuments) {
      state.documents = [...newDocuments];
    },
    setContracts (state, newTreaties) {
      state.contracts = [...newTreaties];
    }
  },
  actions: {
    async getDocuments ({ commit }) {
      try {
        const { data } = await axios.get('/account/documents');
        commit('setDocuments', data);
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async signDocument ({ _, dispatch }, document) {
      try {
        await axios.get('/account/signedDoc', { params: { DocID: document.docID } });
        await dispatch('getDocuments');
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async getContracts ({ commit }) {
      try {
        const { data } = await axios.get('/account/contracts');
        commit('setContracts', data);
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async downloadDocument (_, document) {
      try {
        const { data } = await axios.get(document.url.replace('/api', ''), { responseType: 'blob' });
        return data;
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async viewDocument (_, document) {
      try {
        const { data } = await axios.get(document.urlView.replace('/api', ''), { responseType: 'blob' });
        return data;
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async sendConfirmCode (_, payload) {
      try {
        const response = await axios.post('/settings/contacts/code', {
          ...payload,
          isMobile: isMobileDevice() && payload.type === 'mobile'
        });
        return response.data;
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async confirmCode ({ commit }, payload) {
      try {
        await axios.post('/settings/contacts', { code: payload.code });
        commit('user/updateContactAndConfirm', { contact: payload.contact, contactType: payload.contactType }, { root: true });
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async loadInformingStatus ({ commit }) {
      try {
        const { data } = await axios.get('/settings/informing');
        commit('setInformingStatus', data);
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async enableDisableInforming ({ dispatch }, payload) {
      try {
        const isSmsEnable = payload.sms;
        const isEmailEnable = payload.email;

        if (isSmsEnable) {
          await axios.post('/settings/informing_enable', { type: 'sms' });
        } else {
          await axios.post('/settings/informing_disable', { type: 'sms' });
        }

        if (isEmailEnable) {
          await axios.post('/settings/informing_enable', { type: 'email' });
        } else {
          await axios.post('/settings/informing_disable', { type: 'email' });
        }

        await dispatch('loadInformingStatus');
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async changePassword (_, payload) {
      try {
        await axios.put('/settings/password', payload);
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async createPassword (_, payload) {
      try {
        await axios.post('/settings/createPassword', payload);
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async loadNotifications (_, payload) {
      try {
        const deffaultParams = {
          start: '2000-01-01',
          end: '2099-12-31',
          lines: 9999
        };
        const { data } = await axios.get('/settings/notification', { params: payload ?? deffaultParams }); // === axios.get('/settings/notification?start=2022-01-01&end=2022-12-31&lines=9999');
        return data;
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    }
  },
  getters: {
    informingStatus (state) {
      return state.informingStatus;
    },
    forSignedDocuments (state) {
      return state.documents.filter(document => document.requiresSigning && !document.isSign);
    },
    signedDocuments (state) {
      let arr = [...state.documents];
      arr = arr.filter(document => (document.requiresSigning && document.isSign) || (!document.requiresSigning && !document.isSign))
        .sort((a, b) => {
          return parseDateDocument(b) - parseDateDocument(a);
        });
      return arr;
    },
    contractsGetters (state) {
      return state.contracts;
    }
  }
};
