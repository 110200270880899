<template>
  <div class="page__footer flex-shrink-0">
    <div class="page__footer-inner">
      <div class="page__footer-section order-2">
        <AppLoader
          v-if="isLoading && !isError"
          color="#007CFF"
        />
        <a
          v-else-if="!isLoading && !isError && footer"
          class="link page__footer-agreement-btn"
          :href="footer?.ssylka.value"
          target="_blank"
        >
          {{ footer?.nazvaniye.value }}
        </a>
      </div>
      <div class="page__footer-section order-3">
        <p class="para page__footer-para page__footer-para_short page__footer-support">
          Поддержка:
          <a href="tel:88002502320">8 800 250 2320 </a>
          <span class="mts-d-block-m mts-d-inline-t">Бесплатно</span>
        </p>
        <p class="para page__footer-para page__footer-para_mid page__footer-support">
          Поддержка:
          <a href="tel:88002502320">8 800 250 2320 </a>
          <span class="mts-d-block-m mts-d-inline-t">Бесплатно по России</span>
        </p>
        <p class="para page__footer-para page__footer-para_long page__footer-support">
          Круглосуточная служба поддержки:
          <a href="tel:88002502320">8 800 250 2320 </a>
          <span class="mts-d-block-m mts-d-inline-t">Бесплатно по России</span>
        </p>
      </div>
      <div class="page__footer-section order-1">
        <p class="para page__footer-para page__footer-para_short">
          © {{ new Date().getFullYear() }} ПАО «МТС». 18+
        </p>
        <p class="para page__footer-para page__footer-para_mid page__footer-para_long">
          © {{ new Date().getFullYear() }} ПАО «МТС». 18+
        </p>
      </div>
    </div>
  </div>
  <div
    v-if="!bottomHidden"
    class="page__footer-bottom"
  >
    <div
      v-if="!isDisableLK"
      class="page__footer-nav"
    >
      <router-link
        to="/"
        class="page__footer-nav-link_home page__footer-nav-link"
        href="#"
        @click="gtm.push_events({'event': 'vntMenu', 'eventCategory': 'menu', 'eventAction': 'menu_click', 'eventLabel': 'glavnaya', 'buttonLocation': 'header'})"
      >
        Главная
      </router-link>
      <router-link
        to="/finance"
        class="page__footer-nav-link_money page__footer-nav-link"
        href="#"
        @click="gtm.push_events({'event': 'vntMenu', 'eventCategory': 'menu', 'eventAction': 'menu_click', 'eventLabel': 'finansy', 'buttonLocation': 'header'})"
      >
        Финансы
      </router-link>
      <router-link
        to="/services"
        class="page__footer-nav-link_services page__footer-nav-link"
        href="#"
        @click="gtm.push_events({'event': 'vntMenu', 'eventCategory': 'menu', 'eventAction': 'menu_click', 'eventLabel': 'uslugi', 'buttonLocation': 'header'})"
      >
        Услуги
      </router-link>
      <router-link
        to="/settings"
        class="page__footer-nav-link_settings page__footer-nav-link"
        href="#"
        @click="gtm.push_events({'event': 'vntMenu', 'eventCategory': 'menu', 'eventAction': 'menu_click', 'eventLabel': 'nastroiki', 'buttonLocation': 'header'})"
      >
        Настройки
      </router-link>
      <router-link
        v-if="supportsNavList.length"
        to="/support"
        class="page__footer-nav-link_support page__footer-nav-link"
        href="#"
        @click="gtm.push_events({'event': 'vntMenu', 'eventCategory': 'menu', 'eventAction': 'menu_click', 'eventLabel': 'podderzhka', 'buttonLocation': 'header'})"
      >
        Поддержка
      </router-link>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import AppLoader from '@/components/ui/AppLoader.vue';
import { useGTM } from '@/hooks/gtm';

export default {
  components: { AppLoader },
  props: {
    bottomHidden: {
      type: Boolean,
      required: false
    }
  },
  setup (props) {
    const gtm = useGTM();
    const isLoading = ref(false);
    const isError = ref(false);
    const store = useStore();
    const footer = computed(() => store.getters['cms/getFooter']);
    const supportsNavList = computed(() => store.getters['cms/getSupportChapter']);
    const isDisableLK = store.getters['cms/getIsDisableLK'];
    onMounted(async () => {
      if (footer.value) return;

      try {
        isLoading.value = true;
        await store.dispatch('cms/getCMSFooter');
      } catch (e) {
        isError.value = true;
      } finally {
        isLoading.value = false;
      }
    });

    return {
      isLoading,
      isError,
      footer,
      gtm,
      supportsNavList,
      isDisableLK
    };
  }
};
</script>
