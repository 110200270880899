<template>
  <div
    class="banner"
    @click.prevent="bannerClicked(()=> $emit('replace', false))"
  >
    <picture class="banner__img">
      <source
        srcset="../../assets/content/banner3-mob.jpg"
        media="(max-width: 1279px)"
      >
      <img
        src="../../assets/content/banner3.jpg"
        alt="Оборудование"
      >
    </picture>
    <div class="banner__content">
      <div class="banner__text">
        <div class="banner__text-header">
          Дополнительное оборудование
        </div>
        <div class="banner__para banner__para-mobile">
          Если требуется замена или подключение «Мультирум»
        </div>
      </div>
      <button
        class="banner__btn button button_secondary-bg-gray button_large"
        @click="gtm.push_events({ event: 'vntStv', eventCategory: 'glavnaya', eventAction: 'banner_click', eventLabel: 'zakazat', buttonLocation: 'screen' }); $emit('replace', false)"
      >
        Отправить заявку
      </button>
    </div>
  </div>
</template>

<script setup>
import { useGTM } from '@/hooks/gtm';
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
  inline: {
    required: false,
    type: Boolean,
    default: false
  }
});

defineEmits(['replace']);

const gtm = useGTM();

const bannerClicked = (cb) => {
  if (props.inline) {
    gtm.push_events({ event: 'vntStv', eventCategory: 'glavnaya', eventAction: 'banner_click', eventLabel: 'zakazat', buttonLocation: 'screen' });
    cb();
  }
};
</script>

<style scoped>

</style>
