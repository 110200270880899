import axios from '../../axios/cmsAxios';

export default {
  namespaced: true,
  state: {
    banners: [],
    footer: [],
    logosChannels: [],
    themedBanners: [],
    stocks: [],
    supports: [],
    isDisableLK: false,
    qrCode: '',
    qrLink: ''
  },
  mutations: {
    setBanners (state, banners) {
      state.banners = banners;
    },
    setFooter (state, newFooter) {
      state.footer = newFooter;
    },
    setLogosChannels (state, newLogosChannels) {
      state.logosChannels = newLogosChannels;
    },
    setThemedBanners (state, newBanners) {
      state.themedBanners = newBanners;
    },
    setStocks (state, newStocks) {
      state.stocks = newStocks;
    },
    setSupportsChapter (state, newChapters) {
      state.supports = newChapters;
    },
    setDisableLK (state, value) {
      state.isDisableLK = value.isDisableLK;
      state.qrCode = value.qrCode;
      state.qrLink = value.qrLink;
    }
  },
  actions: {
    async getCMSBanners ({ commit }) {
      try {
        const { data } = await axios(`/sections?productCode=${process.env.VUE_APP_CMS_API}&categoryCode=osnovnie_banneri`);
        commit('setBanners', data[0].articles);
      } catch (e) {
        throw new Error(e);
      }
    },
    async getCMSFooter ({ commit }) {
      try {
        const { data } = await axios(`/sections?productCode=${process.env.VUE_APP_CMS_API}&categoryCode=futer`);
        commit('setFooter', data[0].articles);
      } catch (e) {
        throw new Error(e);
      }
    },
    async getCMSLogosChannels ({ commit }) {
      try {
        const { data } = await axios(`/sections?productCode=${process.env.VUE_APP_CMS_API}&categoryCode=banneri_logotipov_paketov_kanalov`);
        commit('setLogosChannels', data[0].articles);
      } catch (e) {
        throw new Error(e);
      }
    },
    async getCMSThemedBanners ({ commit }) {
      try {
        const { data } = await axios(`/sections?productCode=${process.env.VUE_APP_CMS_API}&categoryCode=tematicheskie_banneri`);
        commit('setThemedBanners', data[0].articles);
      } catch (e) {
        throw new Error(e);
      }
    },
    async getCMSStock ({ commit }) {
      try {
        const { data } = await axios(`/sections?productCode=${process.env.VUE_APP_CMS_API}&categoryCode=bonusi_i_predlozheniya_&showArticleContent=true`);
        commit('setStocks', data[0].articles);
      } catch (e) {
        throw new Error(e);
      }
    },
    async getSupportChapter ({ commit }) {
      try {
        const { data } = await axios(`/sections?productCode=${process.env.VUE_APP_CMS_API}&categoryCode=podderzhka&showArticleContent=true`);
        commit('setSupportsChapter', data);
      } catch (e) {
        throw new Error(e);
      }
    },
    async getDisableLK ({ commit }) {
      try {
        const { data } = await axios(`/sections?productCode=${process.env.VUE_APP_CMS_API}&categoryCode=vklyuchenie_otklyuchenie_lk`);
        const qrCode = data[0].articles[0]?.attributeCategories.vklyuchitotklyuchit.attributes.qr_kod?.value;
        const qrLink = data[0].articles[0]?.attributeCategories.vklyuchitotklyuchit.attributes.ssylka_qr_koda?.value;
        const isDisableLK = data[0].articles[0]?.attributeCategories.vklyuchitotklyuchit.attributes.vklyuchitotklyuchit.value;
        commit('setDisableLK', {
          isDisableLK: isDisableLK === 'true',
          qrCode,
          qrLink
        });
      } catch (e) {
        throw new Error(e);
      }
    }
  },
  getters: {
    getBanners (state) {
      return state.banners;
    },
    getFooter (state) {
      return state.footer[0]?.attributeCategories.futer.attributes;
    },
    getLogosChannels: (state) => (id) => {
      return state.logosChannels.find(channel => Number(channel.sefUrl) === id)?.attributeCategories.bannery_logotipov_paketov_kanalov.attributes;
    },
    getThemedBanners (state) {
      return state.themedBanners;
    },
    getPartnerStock (state) {
      return state.stocks.filter(el => el.attributeCategories.bonusy_i_predlozheniya.attributes.partnerskoye_predlozheniye?.value === 'true');
    },
    getStockById: (state) => (id) => {
      return state.stocks.find(stock => Number(stock.sefUrl) === id);
    },
    getSupportChapter (state) {
      return state.supports.filter(el => el.articles?.length);
    },
    getIsDisableLK (state) {
      return state.isDisableLK;
    },
    getQrCode (state) {
      return state.qrCode;
    },
    getQrLink (state) {
      return state.qrLink;
    }
  }
};
