<template>
  <div
    id="mts-editor"
    class="scrollbar__mts"
    v-html="content"
  />
</template>

<script setup>
import '@developers/mts-editor-dist/dist/style.css';
import { mtsEditorDist } from '@developers/mts-editor-dist';
import { onMounted, onUnmounted } from 'vue';

const props = defineProps({
  content: {
    type: String,
    default: ''
  }
});

onMounted(() => mtsEditorDist.init());
onUnmounted(() => mtsEditorDist.destroy());
</script>

<style>
#mts-editor {
  overflow-x: auto;
}

@media (max-width: 1279px) {
  #mts-editor {
    padding: 20px;
  }
}

#mts-editor .accordion .accordion-content-wrapper .accordion-content,
.ck-editor__main
  .ck-content
  .accordion
  .accordion-content-wrapper
  .accordion-content {
  padding: 0;
}
</style>
