<template>
  <div :class="['banners', {'banners_inline': inline}]">
    <template v-if="!inline">
      <SwiperThemedBaner
        v-for="(item, index) in banners"
        :key="item.id"
        :current-index="currentIndex"
        :index="index"
        :timer-seconds="timerSeconds"
        :size="banners.length"
      >
        <ThemedBanner :banner="item" />
      </SwiperThemedBaner>
    </template>
    <template v-else>
      <ThemedBanner
        v-for="item in banners"
        :key="item.id"
        :banner="item"
      />
    </template>
    <EquipmentHome
      v-if="false"
      :inline="inline"
      @replace="$emit('replace', false)"
    />
  </div>
</template>

<script>
import ThemedBanner from '@/components/baner/ThemedBanners/ThemedBanner.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';
import SwiperThemedBaner from '@/components/baner/ThemedBanners/SwiperThemedBaner.vue';
import { useThemedBannersSwiper } from '@/components/baner/ThemedBanners/useThemedBannersSwiper';
import { CMS_CATEGORIES, isTargetGroup } from '@/utils/cmsHelpers';
import EquipmentHome from '@/components/baner/EquipmentHome';

export default {
  components: { EquipmentHome, SwiperThemedBaner, ThemedBanner },
  props: {
    inline: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const store = useStore();
    const banners = computed(() => store.getters['cms/getThemedBanners'].filter(item => isTargetGroup(item, CMS_CATEGORIES.tematicheskiye_bannery)));

    const swiper = useThemedBannersSwiper(banners.value, 10);

    return {
      banners,
      ...swiper
    };
  }
};
</script>

<style scoped>
@media (max-width: 1279px) {
  .banner__para-mobile {
    display: none;
  }
}
</style>
