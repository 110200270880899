import axios from '@/axios/mainAxios';
import { postRedirect } from '@/utils/postRedirect';
import { dateFormatter } from '@/utils/helpers';

export const useTermination = async (userInfo) => {
  try {
    const { data } = await axios.get('/account/esr_url');

    const { url, hash } = data;

    const {
      email,
      contrNum,
      firstName,
      familyName,
      fatherName,
      docNum,
      docGivenWhen,
      address,
      docGivenWhere,
      mobile,
      phoneCust,
      birthDate
    } = userInfo;

    const series = docNum.substring(0, 4);
    const number = docNum.substring(4);

    const terminationParams = {
      contract_number: contrNum,
      name: firstName,
      surname: familyName,
      patronymic: fatherName,
      series,
      number,
      when_issued: dateFormatter(docGivenWhen, 4),
      unit_code: '',
      issued_by: docGivenWhere,
      registration_address: address,
      phone_number: mobile && mobile.trim() ? mobile : phoneCust,
      birth_date: dateFormatter(birthDate, 4),
      email,
      hash
    };

    postRedirect(url, terminationParams);
  } catch (e) {
    throw { status: e.response.status, data: e.response.data, name: 'account/esr_url' };
  }
};
